import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  TextField,
  SelectInput,
  SelectField,
  Create,
  SimpleForm,
  TextInput,
  EditButton,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton
} from 'react-admin';
import CustomExportButton from "../../components/CustomExportButton";

const Action = ({ total, className, basePath, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      <CustomExportButton
        jobName={'userExportReport'}
        disabled={total === 0}
      />
    </TopToolbar>
  );
};

const ModelList = props => (
  <List
    {...props}
    actions={<Action/>}
  >
    <Datagrid>
      <TextField source='id' />
      <TextField source='email' />
      <SelectField
        source='role'
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
      />
      <EditButton />
    </Datagrid>
  </List>
);

const ModelCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source={`email`} options={{ label: 'Email' }} />
      <TextInput source={`firstName`} options={{ label: 'First Name' }} />
      <TextInput source={`lastName`} options={{ label: 'Last Name' }} />
      <TextInput source={`password`} options={{ label: 'Password' }} />
      <SelectInput
        source={`role`}
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
        options={{ label: 'Role' }}
      />
    </SimpleForm>
  </Create>
);

const ModelEdit = props => (
  <Edit undoable={true} {...props}>
    <SimpleForm>
      <TextInput source={`email`} options={{ label: 'Email' }} />
      <TextInput source={`firstName`} options={{ label: 'First Name' }} />
      <TextInput source={`lastName`} options={{ label: 'Last Name' }} />
      <SelectInput
        source={`role`}
        choices={[
          { id: 'superadmin', name: 'SuperAdmin' },
          { id: 'rentsafe_admin', name: 'Admin' },
          { id: 'property_manager', name: 'Property Manager' },
          { id: 'user', name: 'User' },
        ]}
        options={{ label: 'Role' }}
        disabled={true}
      />
    </SimpleForm>
  </Edit>
);

export default {
  name: 'AdminUser',
  options: {
    label: 'User',
  },
  list: ModelList,
  create: ModelCreate,
  edit: ModelEdit,
};
