export const NUMBER_OF_UNITS = [
  { id: '', name: '- Choose One -', isDefault: true },
  { id: '0-60', name: '0-50' },
  { id: '50-200', name: '50-200' },
  { id: '200-500', name: '200-500' },
  { id: '500-1000', name: '500-1000' },
  { id: '1000+', name: '1000+' },
  { id: 'N/A', name: 'N/A' },
];

export const NUMBER_OF_LEASED_UNITS = [
  { id: '', name: '- Choose One -', isDefault: true },
  { id: '0-50', name: '0-50' },
  { id: '50-200', name: '50-200' },
  { id: '200-500', name: '200-500' },
  { id: '1000+', name: '1000+' },
];
