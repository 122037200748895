import React from 'react';
import Admin from './Admin';
import buildGraphQLProvider from '../ra-data-graphql-simple';
import { client } from 'App';
import Helmet from 'react-helmet';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { provider: null };
  }

  componentDidMount() {
    buildGraphQLProvider({ client })
      .then(provider => {
        this.setState({ provider });
      })
      .catch(err => {
        console.error('Provider Error: ', err);
      });
  }

  render() {
    const { provider } = this.state;

    if (!provider) {
      return <p>Loading...</p>;
    }

    return (
      <>
        <Helmet>
          <title>Admin</title>
        </Helmet>
        <Admin provider={provider} />
      </>
    );
  }
}

export default App;
