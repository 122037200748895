import React from 'react';
import { Button, useNotify } from 'react-admin';
import axios from 'axios';
import { getToken } from "../../utils/auth";

function CustomExportButton({ disabled, jobName }) {
  const [loading, setLoading] = React.useState(false);
  const notify = useNotify();

  React.useEffect(() => setLoading(false), []);

  const handleClick = async () => {
    setLoading(true);

    const jobParam = `jobName=${jobName}`;

    axios
      .get(`${process.env.REACT_APP_API_URL}/api/export?${jobParam}`,
        {
          headers: {
            'Authorization': 'Bearer ' + getToken(),
            'Access-Control-Request-Method': 'GET'
          },

        }
      )
      .then(({ status }) => {
        if (status === 200) {
          notify('Your report is processing and will be emailed to you once completed.');
        }
      })
      .catch(() => notify('Sorry something went wrong. Please try again.', 'warning'))
      .then(() => setLoading(false));
  };

  return <Button label='Export' onClick={handleClick} disabled={disabled || loading} />;
}

export default CustomExportButton;