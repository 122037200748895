import React from 'react';
import PropTypes from 'prop-types';

function AddressField({ record }) {
  return (
    <>
      <span>{record.address1}</span>
      <br />
      {record.address2 && (
        <>
          <span>{record.address2}</span>
          <br />
        </>
      )}
      <span>
        {record.city}, {record.state} {record.zip}
      </span>
    </>
  );
}

AddressField.propTypes = {
  record: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
};

export default AddressField;
