import React from 'react';
import Helmet from 'react-helmet';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Admin from './Admin';
import { getToken, isAuthenticated } from './utils/auth';

const APOLLO_URL =
  process.env.REACT_APP_API_URL + '/graphql' || 'http://localhost:8080/graphql';

export const client = new ApolloClient({
  uri: APOLLO_URL,
  request: operation => {
    if (isAuthenticated()) {
      operation.setContext({
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      });
    }
  },
});

function App() {
  return (
    <ApolloProvider client={client}>
      <Helmet titleTemplate='%s | Rent Safe Lease Admin' />
      <Admin />
    </ApolloProvider>
  );
}

export default App;
