import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  EditButton,
  Edit,
  required,
  TopToolbar,
  sanitizeListRestProps,
  CreateButton,
} from 'react-admin';
import { NUMBER_OF_LEASED_UNITS, NUMBER_OF_UNITS } from 'utils/constants';
import CustomExportButton from "../../components/CustomExportButton";

const Action = ({ total, className, basePath, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CreateButton basePath={basePath} />
      <CustomExportButton
        jobName={'companyExportReport'}
        disabled={total === 0}
      />
    </TopToolbar>
  );
};

const ModelList = props => (
  <List
    {...props}
    actions={<Action/>}
  >
    <Datagrid>
      <TextField source='id' />
      <TextField source='companyName' label={'Company Name'} />
      <TextField
        source='user.email'
        label={'Property Manager Email'}
        sortable={false}
      />
      <EditButton />
    </Datagrid>
  </List>
);

const ModelCreate = props => (
  <Create {...props} redirect={'list'}>
    <SimpleForm undoable={false}>
      <TextInput
        source={`companyName`}
        options={{ label: 'Company Name' }}
        validate={[required()]}
      />
      <SelectInput
        source={`numberOfUnits`}
        choices={NUMBER_OF_UNITS}
        validate={[required()]}
      />
      <SelectInput
        source={`totalLeasedUnits`}
        choices={NUMBER_OF_LEASED_UNITS}
        validate={[required()]}
      />
      <ReferenceInput
        label={`Property Manager`}
        source={'user.id'}
        reference={`AdminUser`}
        validate={[required()]}
      >
        <AutocompleteInput optionText={`email`} source={`user`} />
      </ReferenceInput>
      <TextInput
        source={`address.address1`}
        options={{ label: 'Address Line 1' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.address2`}
        options={{ label: 'Address Line 2' }}
      />
      <TextInput
        source={`address.city`}
        options={{ label: 'City' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.state`}
        options={{ label: 'State' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.zip`}
        options={{ label: 'Zip' }}
        validate={[required()]}
      />
      <TextInput
        source={`address.country`}
        options={{ label: 'Country' }}
        validate={[required()]}
      />
    </SimpleForm>
  </Create>
);

const ModelEdit = props => {
  return (
    <Edit {...props}>
      <SimpleForm undoable={false}>
        <NumberInput source={`id`} disabled />
        <TextInput
          source={`companyName`}
          options={{ label: 'Company Name' }}
          validate={[required()]}
        />
        <SelectInput
          source={`numberOfUnits`}
          choices={NUMBER_OF_UNITS}
          validate={[required()]}
        />
        <SelectInput
          source={`totalLeasedUnits`}
          choices={NUMBER_OF_LEASED_UNITS}
          validate={[required()]}
        />
        <ReferenceInput
          label={`Property Manager`}
          source={'user.id'}
          reference={`AdminUser`}
          validate={[required()]}
        >
          <AutocompleteInput optionText={`email`} source={`user`} />
        </ReferenceInput>
        <TextInput
          source={`address.address1`}
          options={{ label: 'Address Line 1' }}
          validate={[required()]}
        />
        <TextInput
          source={`address.address2`}
          options={{ label: 'Address Line 2' }}
        />
        <TextInput
          source={`address.city`}
          options={{ label: 'City' }}
          validate={[required()]}
        />
        <TextInput
          source={`address.state`}
          options={{ label: 'State' }}
          validate={[required()]}
        />
        <TextInput
          source={`address.zip`}
          options={{ label: 'Zip' }}
          validate={[required()]}
        />
        <TextInput
          source={`address.country`}
          options={{ label: 'Country' }}
          validate={[required()]}
        />
      </SimpleForm>
    </Edit>
  );
};

export default {
  name: 'AdminCompany',
  options: {
    label: 'Company',
  },
  list: ModelList,
  create: ModelCreate,
  edit: ModelEdit,
};
