import React from 'react';
import { Datagrid, List, ReferenceField, TextField, TopToolbar, sanitizeListRestProps } from 'react-admin';
import AddressField from 'components/AddressField';
import CustomExportButton from "../../components/CustomExportButton";

const Action = ({ total, className, ...rest }) => {
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <CustomExportButton
        jobName={'appDataExportReport'}
        disabled={total === 0}
      />
    </TopToolbar>
  );
};

const ModelList = props => (
  <List
    {...props}
    actions={<Action/>}
  >
    <Datagrid>
      <TextField
        source={'user.firstName'}
        label={'First Name'}
        sortable={false}
      />
      <TextField
        source={'user.lastName'}
        label={'Last Name'}
        sortable={false}
      />
      <TextField source={'user.email'} label={'Email'} sortable={false}/>
      <TextField source={'user.phone'} label={'Phone'} sortable={false}/>
      <ReferenceField
        label='Address'
        source='address.id'
        reference='AdminAddress'
        link={false}
      >
        <AddressField source='address'/>
      </ReferenceField>
      <ReferenceField
        label='Application Type'
        source='application.id'
        reference='AdminApplication'
        link={false}
      >
        <TextField source={'type'} label={'Application Type'}/>
      </ReferenceField>
      <TextField
        source={'backgroundCheck.status'}
        label={'Background Check Status'}
      />
    </Datagrid>
  </List>
);

export default {
  name: 'AdminApplicantData',
  options: {
    label: 'ApplicantData',
  },
  list: ModelList,
};
