import gql from 'graphql-tag';
import jwtDecode from 'jwt-decode';
import { client } from 'App';
import { ROLE_RENT_SAFE_ADMIN, ROLE_SUPER_ADMIN } from 'utils/roles';
import { clearSessionStorage, isAuthenticated, setToken } from 'utils/auth';

const LOGIN_MUTATION = gql`
  mutation login($email: Email!, $password: String!) {
    login(data: { email: $email, password: $password }) {
      jwt
    }
  }
`;

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const res = await client.mutate({
        mutation: LOGIN_MUTATION,
        variables: {
          email: username,
          password,
        },
      });

      const {
        data: { login: jwt },
      } = res;
      const data = jwtDecode(jwt.jwt);

      if (
        !data ||
        (data.role !== ROLE_SUPER_ADMIN && data.role !== ROLE_RENT_SAFE_ADMIN)
      ) {
        return Promise.reject(
          'You do not have permission to access this resource.'
        );
      }

      setToken(jwt.jwt);
      return Promise.resolve();
    } catch (err) {
      return Promise.reject(err.message);
    }
  },
  logout: () => {
    clearSessionStorage();
    return Promise.resolve();
  },
  checkError: error => {
    return Promise.resolve();
  },
  checkAuth: () => {
    return isAuthenticated() ? Promise.resolve() : Promise.reject();
  },
  getPermissions: () => {
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
